import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useFonts } from '@use-expo/font'
import { Text, TouchableOpacity, View } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import styled from 'styled-components'
import { colors, sizes, textStyles } from '../styles/variables'
import { Asset } from 'expo-asset'

// Fonts
import Hind from '../../assets/fonts/Hind/Hind-Regular.ttf'
import HindBold from '../../assets/fonts/Hind/Hind-SemiBold.ttf'
import PTBold from '../../assets/fonts/Futura/FuturaPTBold.otf'

const Card = styled(TouchableOpacity)`
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: ${sizes.xxs}px ${sizes.base}px;
  margin-top: ${sizes.sm}px;
  background-color: ${colors.pureWhite};
  border-radius: 3px;
`

const TextBlock = styled(View)`
  width: 60%;
`

const Title = styled(Text)`
  font-family: 'HindBold';
  font-size: ${sizes.base}px;
  margin-top: ${sizes.xxs}px;
  color: ${colors.oil};
  line-height: ${sizes.md}px;
`

const Btn = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center
  justify-content: space-between;
  width: 100px;
  text-align: center;
  background: ${(props) => (props.continue ? colors.summerSky : colors.green)};
  padding: ${sizes.xxxs}px ${sizes.xs}px;
  margin: ${sizes.sm}px 0;
  border-radius: 3px;
`

const EnrollmentCard = ({
  course_id: itemId,
  course_name,
  percentage_completed,
}) => {
  const navigation = useNavigation()
  const [isLoaded] = useFonts({
    Hind: Asset.fromModule(Hind).uri,
    HindBold: Asset.fromModule(HindBold).uri,
    PTBold: Asset.fromModule(PTBold).uri,
  })

  return (
    isLoaded && (
      <Card onPress={() => navigation.navigate('Course', { id: itemId })}>
        <TextBlock>
          <Title>{course_name}</Title>
        </TextBlock>
        <Btn continue={percentage_completed !== '0.0'}>
          <Text style={{ color: colors.white }}>
            {percentage_completed !== '0.0' ? 'Continue' : 'Start'}
          </Text>
          <Ionicons
            name='ios-arrow-round-forward'
            size={24}
            color={colors.white}
          />
        </Btn>
      </Card>
    )
  )
}

export default EnrollmentCard
