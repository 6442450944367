import React, { useEffect, useState } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Ionicons } from '@expo/vector-icons'
import { colors } from '../styles/variables'
import styled from 'styled-components'

const Stack = createStackNavigator()
const Tab = createBottomTabNavigator()

// Pages
import HomeMenu from '../menu/home'
import UserMenu from '../menu/user'
import AboutMenu from '../menu/about'
import Login from '../pages/login'

import { SafeAreaView } from 'react-native'

// Styles
const Container = styled(SafeAreaView)`
  flex: 1;
  width: 100%;
  height: 100vh;
  padding-top: 0;
`

const Main = ({ authState, onStateChange }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 500)
  }, [authState])

  return (
    loaded && (
      <Container>
        {authState === 'signIn' || authState === 'loading' ? (
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
              headerStyle: {
                backgroundColor: colors.jet,
              },
              headerBackTitleVisible: false,
            }}>
            <Stack.Screen name='Login'>
              {() => (
                <Login
                  handleAuthStateChange={(val, user) =>
                    onStateChange(val, user)
                  }
                />
              )}
            </Stack.Screen>
          </Stack.Navigator>
        ) : (
          <Tab.Navigator
            initialRouteName='HomeMenu'
            tabBarOptions={{
              activeBackgroundColor: colors.jet,
              inactiveBackgroundColor: colors.jet,
              activeTintColor: colors.white,
              inactiveTintColor: colors.lightGrey,
              showLabel: false,
              labelStyle: {
                fontSize: 12,
              },
              tabStyle: {
                width: 100,
                paddingTop: 5,
                paddingBottom: 0,
              },
              style: {
                backgroundColor: colors.jet,
                shadowColor: 'transparent',
                borderTopWidth: 0,
                borderTopColor: 'transparent',
                height: '49px',
                position: 'absolute',
                bottom: 0,
                paddingTop: '20px',
              },
            }}
            screenOptions={({ route }) => ({
              tabBarIcon: ({ focused, color, size }) => {
                let iconName
                if (route.name === 'Your Profile') {
                  iconName = focused ? 'ios-contact' : 'ios-contact'
                } else if (route.name === 'Home') {
                  iconName = focused ? 'ios-list-box' : 'ios-list'
                } else if (route.name === 'About') {
                  iconName = focused
                    ? 'ios-information-circle'
                    : 'ios-information-circle-outline'
                }
                return <Ionicons name={iconName} size={size} color={color} />
              },
            })}>
            <Tab.Screen component={HomeMenu} name='Home' />
            <Tab.Screen component={UserMenu} name='Your Profile' />
            <Tab.Screen component={AboutMenu} name='About' />
          </Tab.Navigator>
        )}
      </Container>
    )
  )
}

export default Main
