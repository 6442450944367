import React, { useState, useEffect } from 'react'
import * as WebBrowser from 'expo-web-browser'
import { Auth } from 'aws-amplify'
import { Asset } from 'expo-asset'
import {
  SafeAreaView,
  Image,
  ImageBackground,
  View,
  Text,
  TextInput,
  TouchableOpacity,
} from 'react-native'
import styled from 'styled-components/native'
import CodeInput from 'react-code-input'
import Loading from '../components/loading'
import { useFonts } from '@use-expo/font'
import { sizes, colors, textStyles } from '../styles/variables'
import Constants from 'expo-constants'
import client from '../services/contentful'
const { DEMO_EMAIL } = Constants.manifest.extra

// Images
import CoverImg from '../../assets/cover.jpeg'
import LogoImg from '../../assets/logo.png'

// Fonts
import Hind from '../../assets/fonts/Hind/Hind-Regular.ttf'
import HindBold from '../../assets/fonts/Hind/Hind-SemiBold.ttf'
import PTBold from '../../assets/fonts/Futura/FuturaPTBold.otf'

// Styles
const Container = styled(SafeAreaView)`
  height: 100vh;
  background-color: ${colors.jet};
`

const Wrapper = styled(View)`
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`

const CoverImage = styled(ImageBackground)`
  height: auto;
  width: 100vw;
`

const Logo = styled(Image)`
  height: 100px;
  width: 150px;
  margin-top: 30px;
`

const Overlay = styled(View)`
  background: ${colors.overlay};
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
`

const Form = styled(View)`
  background: ${colors.black};
  padding: 50px 30px;
  border-radius: 2px;
  width: 300px;
`

const CodeInputContainer = styled(View)`
  width: calc(100% + 8px);
  align-items: center;
  margin-left: -4px;
  margin-top: 30px;
  margin-bottom: 14px;
`

const Field = styled(TextInput)`
  height: 40px;
  border: 1px solid ${colors.grey};
  color: ${colors.white};
  margin-top: 30px;
  padding: 5px 10px;
  width: 100%;
  text-align: center;
`

const Title = styled(Text)`
  font-family: 'PTBold';
  ${textStyles.title};
  font-size: ${sizes.md}px;
  color: ${colors.white};
  margin-bottom: 0;
  text-align: center;
`

const Body = styled(Text)`
  font-family: 'Hind';
  font-size: ${sizes.sm}px;
  margin-top: ${sizes.xxs}px;
  color: ${colors.white};
  text-align: center;
`

const Btn = styled(TouchableOpacity)`
  background-color: ${({ isActive }) =>
    isActive ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.3)'};
  background: ${colors.summerSky};
  padding: 15px 30px;
  width: 150px;
  border-radius: 3px;
  margin: 30px auto 0;
`

const BtnText = styled(Text)`
  text-align: center;
`

const Error = styled(Text)`
  color: red;
  text-align: center;
  margin-top: 20px;
  font-size: ${sizes.xs}px;
`

const BtnContainer = styled(Text)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
  margin-bottom: 40px;
  align-items: flex-end;
  color: ${colors.white};
  padding-top: 20px;
  position: relative;
`

const Explainer = styled(Text)`
  font-size: 12px;
  color: ${colors.white};
  margin-top: 20px;
  text-align: center;
  white-space: none !important;
`

const Login = ({ handleAuthStateChange }) => {
  const [submitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [user, setUser] = useState(null)
  const [showCode, setShowCode] = useState(false)
  const [showError, setShowError] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [content, setContent] = useState([])
  const [links, setLoginLinks] = useState([])

  const [isLoaded] = useFonts({
    Hind: Asset.fromModule(Hind).uri,
    HindBold: Asset.fromModule(HindBold).uri,
    PTBold: Asset.fromModule(PTBold).uri,
  })

  const handleGenerateCode = async () => {
    setSubmitting(true)
    setShowError(false)
    try {
      if (email === DEMO_EMAIL) {
        handleAuthStateChange('demoUser', user)
      } else {
        const user = await Auth.signIn(email.toLowerCase().trim())
        setUser(user)
        setShowCode(true)
        setSubmitting(false)
      }
    } catch (err) {
      setEmail('')
      setShowError(true)
      setSubmitting(false)
    }
  }

  const handleSignIn = async () => {
    setSubmitting(true)
    setShowError(false)
    try {
      const answer = await Auth.sendCustomChallengeAnswer(user, code)
      if (answer) {
        handleAuthStateChange('signedIn', user)
      }
    } catch (err) {
      setShowCode(false)
      setCode('')
      setShowError(true)
      setSubmitting(false)
    }
  }

  const checkSetCode = (code) => {
    setCode(code.length === 6 ? code : '')
  }

  const imageURI = Asset.fromModule(CoverImg).uri
  const logoURI = Asset.fromModule(LogoImg).uri

  const fetchData = async () => {
    const entriesObj = {
      content_type: 'loginPage',
    }

    const response = await client.getEntries(entriesObj)
    if (response) {
      setLoaded(true)
      setContent(response.items[0].fields)
      setLoginLinks(response.items[0].fields.loginLinks)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const showLinks = links.map((a) => (
    <Btn onPress={() => WebBrowser.openBrowserAsync(a.fields.url)}>
      <BtnText>{a.fields.label}</BtnText>
    </Btn>
  ))

  return (
    isLoaded && (
      <Container>
        <CoverImage source={{ uri: imageURI }}>
          <Overlay>
            <Logo source={{ uri: logoURI }} style={{ resizeMode: 'contain' }} />

            <Wrapper>
              <Form>
                {submitting ? (
                  <Loading size='large' color={colors.white} />
                ) : !showCode ? (
                  <>
                    <Title>{content.title}</Title>
                    <Body>Enter your email address</Body>
                    <Field
                      onChangeText={(text) => setEmail(text)}
                      value={email}
                      placeholder='hello@wildonlineworkouts.com'
                      autoCompleteType='email'
                      keyboardType='email-address'
                      textContentType='emailAddress'
                      autoCapitalize='none'
                      placeholderTextColor={colors.opache}
                      required
                    />
                    {showError && (
                      <Error>There's been an error, please try again</Error>
                    )}
                    <Btn
                      onPress={() => handleGenerateCode()}
                      isActive={email}
                      disabled={!email}>
                      <BtnText>Submit</BtnText>
                    </Btn>
                    <Explainer
                      dangerouslySetInnerHTML={{ __html: content.text }}
                    />
                  </>
                ) : (
                  <>
                    <Title>
                      Please check your email and enter the code provided
                    </Title>
                    <CodeInputContainer>
                      <CodeInput
                        type='number'
                        className='code-input-container'
                        onChange={(code) => checkSetCode(code)}
                        inputStyle={{
                          fontSize: sizes.md,
                          height: 50,
                          width: '26px',
                          margin: '4px',
                          padding: '1px 2px',
                          border: '1px solid rgba(255, 255, 255, 0.3)',
                          backgroundColor: 'transparent',
                          textAlign: 'center',
                          color: colors.white,
                        }}
                        fields={6}
                        required
                      />
                    </CodeInputContainer>
                    {showError && (
                      <Error>There's been an error, please try again</Error>
                    )}
                    <Btn
                      onPress={() => handleSignIn()}
                      isActive={code}
                      disabled={!code}>
                      <BtnText>Enter</BtnText>
                    </Btn>
                  </>
                )}
              </Form>
              <BtnContainer>{showLinks}</BtnContainer>
            </Wrapper>
          </Overlay>
        </CoverImage>
      </Container>
    )
  )
}

export default Login
