import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

// Pages
import Home from '../pages/home'
import Course from '../pages/course'

const CoursesStack = createStackNavigator()

const HomeMenu = () => (
  <CoursesStack.Navigator
    initialRouteName='Home'
    screenOptions={{
      headerShown: false,
    }}>
    <CoursesStack.Screen component={Home} name='Welcome' />
    <CoursesStack.Screen component={Course} name='Course' />
  </CoursesStack.Navigator>
)

export default HomeMenu
