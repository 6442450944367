import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useFonts } from '@use-expo/font'
import { View, Text } from 'react-native'
import styled from 'styled-components'
import { includes, startCase } from 'lodash'
import { sizes, colors, textStyles } from '../styles/variables'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { getClawColor } from '../utils/utils'
import { Asset } from 'expo-asset'

// Fonts
import Hind from '../../assets/fonts/Hind/Hind-Regular.ttf'
import HindBold from '../../assets/fonts/Hind/Hind-SemiBold.ttf'
import PTBold from '../../assets/fonts/Futura/FuturaPTBold.otf'

const colorArr = ['Hunter', 'Warrior']

const Card = styled(View)`
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  padding: ${sizes.sm}px ${sizes.md}px;
  margin-top: ${sizes.base}px;
`

const Stats = styled(View)`
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  padding: 0 ${sizes.md}px ${sizes.md}px;
  margin-top: ${sizes.xs}px;
`

const Title = styled(Text)`
  font-family: 'PTBold';
  ${textStyles.title};
  color: ${(props) =>
    includes(colorArr, props.colour) ? colors.oil : colors.white};
  font-size: ${sizes.lg}px;
  margin-bottom: 0;
`

const Excerpt = styled(Text)`
  font-family: 'Hind';
  color: ${(props) =>
    includes(colorArr, props.colour) ? colors.oil : colors.white};
  margin-bottom: 0;
`

const Col = styled(View)`
  width: 70%;
`

const Logout = styled(View)`
  flex: 1;
  width: 20%;
  text-align: right;
  align-items: flex-end;
`
const Stat = styled(Text)`
  color: ${(props) =>
    includes(colorArr, props.colour) ? colors.oil : colors.white};
  margin-bottom: ${sizes.xxxs}px;
`

const Flex = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TextBtn = styled(TouchableOpacity)`
  padding: ${sizes.xxs}px ${sizes.xs}px;
  background-color: ${colors.opache};
  color: ${(props) =>
    includes(colorArr, props.colour) ? colors.green : colors.orange}
  border-radius: 2px;
`

const UserCard = ({
  full_name,
  email,
  lessons,
  courses,
  points,
  pointsTotal,
  colour,
  loaded,
}) => {
  const [isLoaded] = useFonts({
    Hind: Asset.fromModule(Hind).uri,
    HindBold: Asset.fromModule(HindBold).uri,
    PTBold: Asset.fromModule(PTBold).uri,
  })

  const handleSignOut = async () => {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    isLoaded && (
      <>
        <Card clawColor={getClawColor(colour)}>
          <Col>
            <Title colour={colour}>{startCase(full_name)}</Title>
            <Excerpt colour={colour}>{email}</Excerpt>
          </Col>
          <Logout>
            <TextBtn onPress={() => handleSignOut()}>
              <Text>Log out</Text>
            </TextBtn>
          </Logout>
        </Card>

        <Stats>
          {loaded && (
            <Stat colour={colour}>
              {colour
                ? `${startCase(colour)} level`
                : 'Get started to earn your first points'}
            </Stat>
          )}
          {loaded && points && (
            <Flex>
              <Stat
                style={{ textAlign: 'right', marginRight: 5 }}
                colour={colour}>
                {points && `${points} claw points this month`}
              </Stat>
            </Flex>
          )}
          {loaded && pointsTotal && (
            <Flex>
              <Stat
                style={{ textAlign: 'right', marginRight: 5 }}
                colour={colour}>
                {pointsTotal && `${pointsTotal} claw points all time`}
              </Stat>
            </Flex>
          )}

          {loaded && (
            <Stat colour={colour}>
              {courses && courses.length > 0 ? courses.length : 0}{' '}
              {courses && courses.length === 1 ? 'course' : 'courses'}
            </Stat>
          )}

          {loaded && (
            <Stat colour={colour}>
              {lessons && lessons.length > 0 ? lessons.length : 0}{' '}
              {lessons && lessons.length === 1 ? 'lesson' : 'lessons'}
            </Stat>
          )}
        </Stats>
      </>
    )
  )
}

export default UserCard
