import { client } from './client'
import { Auth } from 'aws-amplify'

const getEnrollments = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const response = await client.get(
      `/enrollments?query[user_id]=${user.attributes['custom:uuid']}`
    )
    return response.data
  } catch (error) {
    return { err: error }
  }
}

const getEnrollment = async (id) => {
  try {
    const response = await client.get(`/enrollments/${id}`)
    return response.data
  } catch (error) {
    return { err: error }
  }
}

export { getEnrollments, getEnrollment }
