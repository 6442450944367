import { colors } from '../styles/variables'

const secondsToHms = (d) => {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)
  var hDisplay = h > 0 ? h + 'h ' : ''
  var mDisplay = m > 0 ? m + 'm ' : ''
  var sDisplay = s > 0 ? s + 's ' : ''
  return hDisplay + mDisplay + sDisplay
}

const getClawColor = (colour) => {
  if (colour === 'Omega') {
    return colors.omega
  } else if (colour === 'Hunter') {
    return colors.hunter
  } else if (colour === 'Warrior') {
    return colors.warrior
  } else if (colour === 'Lead Warrior') {
    return colors.leadWarrior
  } else if (colour === 'Delta') {
    return colors.delta
  } else if (colour === 'Beta') {
    return colors.beta
  } else if (colour === 'Alpha') {
    return colors.alpha
  } else if (colour === 'Apex') {
    return colors.apex
  } else {
    return colors.darkGrey
  }
}

export { secondsToHms, getClawColor }
