import React from 'react'
import { AmplifyTheme, Authenticator } from 'aws-amplify-react-native'
import Amplify from '@aws-amplify/core'
import config from './aws-exports'
import Main from './src/components/main'
import { NavigationContainer } from '@react-navigation/native'
import { StatusBar } from 'expo-status-bar'
import { colors } from './src/styles/variables'

const MyTheme = Object.assign({}, AmplifyTheme, {
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: '100vh',
    backgroundColor: colors.jet,
  },
})

Amplify.configure({
  ...config,
  Analytics: {
    disabled: true,
  },
})

const App = () => (
  <NavigationContainer>
    <Authenticator hideDefault={true} amplifyConfig={config} theme={MyTheme}>
      <Main />
    </Authenticator>
    <StatusBar style='light' />
  </NavigationContainer>
)

export default App
