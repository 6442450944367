import { custom } from './client'
import { Auth } from 'aws-amplify'
import Constants from 'expo-constants'
const { USER_ID } = Constants.manifest.extra

const getCollections = async () => {
  try {
    const user = (await Auth.currentAuthenticatedUser()) || USER_ID
    const jwt = user.signInUserSession.idToken.jwtToken
    const response = await custom.get('/collections', {
      headers: { Authorization: jwt },
    })
    if (response.data) {
      const data = JSON.parse(response.data.body)
      return data
    } else {
      return { err: 'error' }
    }
  } catch (error) {
    return { err: error }
  }
}

export { getCollections }
