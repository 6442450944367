import React, { useCallback, useState, useEffect } from 'react'
import {
  SafeAreaView,
  ScrollView,
  Text,
  View,
  RefreshControl,
} from 'react-native'
import { Asset } from 'expo-asset'
import styled from 'styled-components'
import { useFonts } from '@use-expo/font'
import { includes, isEmpty } from 'lodash'
import { sizes, colors, textStyles } from '../styles/variables'
import { getUser, getUserStats } from '../services/users'
import { getEnrollments } from '../services/enrollments'
import EnrollmentCard from '../components/enrollmentCard'
import UserCard from '../components/userCard'
import Loading from '../components/loading'
import { getClawColor } from '../utils/utils'

// Fonts
import Hind from '../../assets/fonts/Hind/Hind-Regular.ttf'
import HindBold from '../../assets/fonts/Hind/Hind-SemiBold.ttf'
import PTBold from '../../assets/fonts/Futura/FuturaPTBold.otf'

const colorArr = ['Hunter', 'Warrior']

// Styles
const Container = styled(SafeAreaView)`
  flex: 1;
  height: 100vh;
  background-color: ${colors.jet};
  padding-top: ${sizes.md}px;
`

const Wrapper = styled(ScrollView)`
  height: calc(100vh - 47px);
`

const Angle = styled(View)`
  position: absolute;
  width: 100%;
  height: 50px;
  padding-top: 300px;
  top: 0;
  left: 0;
  background-color: ${colors.grey};
  border-bottom-width: 80px;
  border-bottom-color: ${colors.grey};
  border-style: solid;
  border-left-width: 600px;
  border-left-color: ${(props) =>
    props.clawColor ? props.clawColor : colors.jet};
`

const Section = styled(View)`
  margin-bottom: ${sizes.md}px;
  padding: 0px ${sizes.md}px;
  position: relative;
`

const SubTitle = styled(Text)`
  font-family: 'PTBold';
  ${textStyles.title};
  font-size: ${sizes.base}px;
  margin: ${sizes.sm}px 0 0;
  color: ${(props) =>
    includes(colorArr, props.colour) ? colors.oil : colors.white};
`

const Empty = styled(Text)`
  margin-top: ${sizes.sm}px;
  color: ${(props) =>
    includes(colorArr, props.colour) ? colors.oil : colors.white};
`

const LoadingWrapper = styled(View)`
  padding: 50px;
`

const User = () => {
  const [user, setUser] = useState([])
  const [enrollments, setEnrollments] = useState([])
  const [userStats, setUserStats] = useState([])
  const [contentLoaded, setContentLoaded] = useState(false)
  const [refreshing, setRefreshing] = useState(false)

  const [isLoaded] = useFonts({
    Hind: Asset.fromModule(Hind).uri,
    HindBold: Asset.fromModule(HindBold).uri,
    PTBold: Asset.fromModule(PTBold).uri,
  })

  useEffect(() => {
    fetchUser()
  }, [refreshing])

  const fetchUser = async () => {
    const userProfile = await getUser()
    if (!userProfile.err) {
      setUser(userProfile)
    }
    const userEnrollments = await getEnrollments()
    if (!userEnrollments.err) {
      setEnrollments(userEnrollments.items)
    }
    const stats = await getUserStats()
    if (!stats.err) {
      setUserStats(stats)
    }
    setTimeout(() => {
      setContentLoaded(true)
    }, 1000)
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    setContentLoaded(false)
    setTimeout(() => {
      setRefreshing(false)
    })
  }, [])

  return (
    isLoaded && (
      <Container>
        <Wrapper
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              tintColor={colors.darkGrey}
            />
          }>
          <Angle clawColor={getClawColor(userStats.colour)} />
          {contentLoaded ? (
            <>
              <View>
                <UserCard {...user} {...userStats} loaded={contentLoaded} />
              </View>

              <Section>
                <SubTitle colour={userStats.colour}>Your enrolments</SubTitle>
                {!isEmpty(enrollments) ? (
                  <>
                    {enrollments.map((e) => (
                      <EnrollmentCard key={e.id} {...e} />
                    ))}
                  </>
                ) : (
                  <Empty colour={userStats.colour}>
                    You've not enroled in any courses yet
                  </Empty>
                )}
              </Section>
            </>
          ) : (
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          )}
        </Wrapper>
      </Container>
    )
  )
}

export default User
