import axios from 'axios'
import Constants from 'expo-constants'
const {
  THINKIFIC_API_ENDPOINT,
  CUSTOM_API_ENDPOINT,
  API_KEY,
  SUBDOMAIN,
} = Constants.manifest.extra

console.log('CUSTOM_API_ENDPOINT', CUSTOM_API_ENDPOINT)

const client = axios.create({
  baseURL: THINKIFIC_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'X-Auth-API-Key': API_KEY,
    'X-Auth-Subdomain': SUBDOMAIN,
  },
  timeout: 60000,
})

const custom = axios.create({
  baseURL: CUSTOM_API_ENDPOINT,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 60000,
})

export { client, custom }
