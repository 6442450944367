/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:cd3f3692-376b-4f22-8ec4-2b989f3acf13",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ecKqgoBkJ",
    "aws_user_pools_web_client_id": "1arphfrjtv43bu4r71c0efshc9",
    "oauth": {}
};


export default awsmobile;
