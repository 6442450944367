import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import styled from 'styled-components/native'

const Container = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  padding: 30px;
`

const Loading = ({ size, colour }) => (
  <Container>
    <ActivityIndicator size={size} color={colour} />
  </Container>
)

export default Loading
