import React, { useCallback, useState, useEffect } from 'react'
import {
  SafeAreaView,
  ScrollView,
  View,
  Text,
  RefreshControl,
} from 'react-native'
import { Asset } from 'expo-asset'
import styled from 'styled-components'
import { useFonts } from '@use-expo/font'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import { sizes, colors, textStyles } from '../styles/variables'
import { getUser, getUserStats } from '../services/users'
import { getCollections } from '../services/collections'
import { getCourse } from '../services/courses'
import { getEnrollments } from '../services/enrollments'
import Profile from '../components/profile'
import CourseCard from '../components/courseCard'
import Loading from '../components/loading'

// Fonts
import Hind from '../../assets/fonts/Hind/Hind-Regular.ttf'
import HindBold from '../../assets/fonts/Hind/Hind-SemiBold.ttf'
import PTBold from '../../assets/fonts/Futura/FuturaPTBold.otf'

// Styles
const Container = styled(SafeAreaView)`
  flex: 1;
  height: 100vh;
  background-color: ${colors.jet};
  padding-top: ${sizes.md}px;
`

const Wrapper = styled(ScrollView)`
  background-color: ${colors.oil};
  height: calc(100vh - 47px);
`

const Angle = styled(View)`
  position: absolute;
  width: 100%;
  height: 50px;
  padding-top: 300px;
  top: 0;
  left: 0;
  background-color: ${colors.oil};
  border-bottom-width: 80px;
  border-bottom-color: ${colors.oil};
  border-style: solid;
  border-left-width: 600px;
  border-left-color: ${colors.jet};
`

const Section = styled(View)`
  margin-bottom: ${sizes.md}px;
  padding: ${(props) =>
    props.pad ? `0px ${sizes.md}px` : `0px 0px 0px ${sizes.md}px`};
`

const SubTitle = styled(Text)`
  font-family: 'PTBold';
  ${textStyles.title};
  font-size: ${sizes.base}px;
  margin: ${sizes.sm}px 0 0;
  color: ${colors.white};
`

const Home = () => {
  const [collections, setCollections] = useState([])
  const [enrollments, setEnrollments] = useState([])
  // const [newReleases, setNewReleases] = useState([])
  const [user, setUser] = useState([])
  const [userStats, setUserStats] = useState(null)
  const [contentLoaded, setContentLoaded] = useState(false)
  const [statsLoaded, setStatsLoaded] = useState(false)
  const [refreshing, setRefreshing] = useState(false)

  const [isLoaded] = useFonts({
    Hind: Asset.fromModule(Hind).uri,
    HindBold: Asset.fromModule(HindBold).uri,
    PTBold: Asset.fromModule(PTBold).uri,
  })

  useEffect(() => {
    fetchUser()
    fetchCollections()
    fetchEnrollments()
    setTimeout(() => {
      setContentLoaded(true)
    }, 600)
  }, [refreshing])

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    setStatsLoaded(false)
    setContentLoaded(false)
    setTimeout(() => {
      setRefreshing(false)
    }, 300)
  }, [])

  const fetchUser = async () => {
    const userProfile = await getUser()
    const stats = await getUserStats()
    if (!userProfile.err) {
      setUser(userProfile)
    }
    if (!stats.err) {
      setUserStats(stats)
    }
    setStatsLoaded(true)
  }

  const fetchEnrollments = async () => {
    const userEnrollments = await getEnrollments()
    const arr = []
    await Promise.all(
      userEnrollments.items.map(async (item, index) => {
        if (index < 6) {
          const res = await getCourse(item.course_id)
          if (res.keywords.search('hidden') === -1) {
            arr.push(res)
          }
        }
      })
    )
    setEnrollments(arr)
  }

  const fetchCollections = async () => {
    const collectionsResult = await getCollections()
    console.log()
    const sortedResults = sortBy(collectionsResult.Items, ['position.N'])
    // const newReleaseIndex = 2
    // const newReleases = sortedResults[newReleaseIndex]

    // sortedResults.splice(newReleaseIndex, 1)
    // sortedResults.unshift(newReleases)
    setCollections(sortedResults)
  }

  return (
    <Container>
      {isLoaded && (
        <Wrapper
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              tintColor={colors.white}
            />
          }>
          <Angle />
          {user && <Profile {...user} {...userStats} loaded={statsLoaded} />}
          {contentLoaded ? (
            <>
              {/* {newReleases.length > 0 && (
                <>
                  {newReleases[0].items.length > 0 && (
                    <Section pad={true}>
                      <SubTitle>{newReleases[0].name}</SubTitle>
                      {newReleases[0].items.map((c) => (
                        <CourseCard
                          key={c.id}
                          {...c}
                          itemId={c.productable_id}
                          itemImage={c.card_image_url}
                          completed={userStats && userStats.courses}
                        />
                      ))}
                    </Section>
                  )}
                </>
              )} */}

              {enrollments.length > 0 && (
                <Section>
                  <SubTitle>Jump back in</SubTitle>
                  <ScrollView horizontal={true}>
                    {enrollments.map((c) => (
                      <CourseCard
                        key={c.id}
                        {...c}
                        itemId={c.id}
                        itemImage={c.course_card_image_url}
                        variant='horizontal'
                        completed={userStats && userStats.courses}
                      />
                    ))}
                  </ScrollView>
                </Section>
              )}

              {collections.length > 0 &&
                collections.map((course) => {
                  const p = JSON.parse(course.products.S)
                  const items = p.filter(
                    (i) =>
                      i.hidden.BOOL &&
                      i.status.S === 'published' &&
                      i.productable_type.S === 'Course'
                  )
                  // p.map(
                  //   (e) => console.log(e.name.S, e.position)
                  //   // e.name.S === 'Buy the Urban Strength Bar' &&
                  //   // console.log(e.name.S, e.hidden.BOOL)
                  // )
                  return (
                    !isEmpty(items) && (
                      <React.Fragment key={course.id.S}>
                        <Section>
                          <SubTitle>{course.name.S}</SubTitle>
                          <ScrollView horizontal={true}>
                            {items.map((c) => (
                              <CourseCard
                                key={c.id.S}
                                {...c}
                                itemId={c.productable_id.S}
                                itemImage={c.card_image_url.S}
                                variant='horizontal'
                                completed={userStats && userStats.courses}
                              />
                            ))}
                          </ScrollView>
                        </Section>
                      </React.Fragment>
                    )
                  )
                })}
            </>
          ) : (
            <Loading />
          )}
        </Wrapper>
      )}
    </Container>
  )
}

export default Home
