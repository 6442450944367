const sizes = {
  xxxs: 4,
  xxs: 8,
  xs: 12,
  sm: 14,
  ms: 16,
  base: 18,
  md: 22,
  lg: 26,
  xl: 30,
  xxl: 34,
  xxxl: 48,
  xxxxl: 72,
}

const colors = {
  pureWhite: '#FFFFFF',
  white: '#e5e5e5',
  black: '#000000',
  oil: '#222222',
  darkGrey: '#292A2C',
  grey: '#DDDDDD',
  lightGrey: '#CCCCCC',
  jet: '#18181a',
  overlay: 'rgba(00, 00, 00, 0.5)',
  opache: 'rgba(255, 255, 255, 0.5)',

  yellow: '#ffea00',
  orange: '#ff9000',
  green: '#545454',
  blue: '#3333ff',
  brown: '#663300',
  summerSky: '#40BADD',

  omega: '#292A2C',
  hunter: '#ffffff',
  warrior: '#A6A6A6',
  leadWarrior: '#004AAD',
  delta: '#FF0000',
  beta: '#AB40DD',
  alpha: '#545454',
  apex: '#40BADD',
}

const layout = {
  borderRadius: '5px',
  boxShadow: `0px 0px 4px ${colors.oil}`,
}

const textStyles = {
  title: {
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  body: {
    lineHeight: `${sizes.base}px`,
    color: colors.darkGrey,
  },
}

export { colors, sizes, layout, textStyles }
