import React from 'react'
import * as WebBrowser from 'expo-web-browser'
import { useFonts } from '@use-expo/font'
import includes from 'lodash/includes'
import { Text, View, TouchableOpacity } from 'react-native'
import { Ionicons, AntDesign } from '@expo/vector-icons'
import styled from 'styled-components'
import { colors, sizes, font } from '../styles/variables'
import { Asset } from 'expo-asset'

// Fonts
import Hind from '../../assets/fonts/Hind/Hind-Regular.ttf'
import HindBold from '../../assets/fonts/Hind/Hind-SemiBold.ttf'
import PTBold from '../../assets/fonts/Futura/FuturaPTBold.otf'

const Card = styled(TouchableOpacity)`
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: ${sizes.sm}px ${sizes.md}px;
  margin-top: ${sizes.sm}px;
  background-color: ${(props) =>
    props.completed ? `${colors.lightGrey}80` : colors.lightGrey};
  border-radius: 3px;
`

const Title = styled(Text)`
  font-family: 'Hind';
  font-size: 14px;
`

const Flex = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ChapterCard = ({ id, name, take_url, completed }) => {
  const [isLoaded] = useFonts({
    Hind: Asset.fromModule(Hind).uri,
    HindBold: Asset.fromModule(HindBold).uri,
    PTBold: Asset.fromModule(PTBold).uri,
  })

  const itemCompleted = () => {
    return includes(id, completed)
  }

  return (
    isLoaded && (
      <Card
        onPress={() => WebBrowser.openBrowserAsync(take_url)}
        completed={itemCompleted()}>
        <Title>{name}</Title>
        <Flex>
          {itemCompleted() && (
            <View style={{ marginRight: sizes.xxs }}>
              <AntDesign
                name='checkcircleo'
                size={sizes.base}
                color={colors.green}
              />
            </View>
          )}
          <Ionicons name='ios-arrow-round-forward' size={24} color='black' />
        </Flex>
      </Card>
    )
  )
}

export default ChapterCard
