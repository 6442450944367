import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useFonts } from '@use-expo/font'
import includes from 'lodash/includes'
import isObject from 'lodash/isObject'
import { AntDesign } from '@expo/vector-icons'
import { ImageBackground, Text, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import { LinearGradient } from 'expo-linear-gradient'
import { colors, sizes, layout } from '../styles/variables'
import { Asset } from 'expo-asset'

// Fonts
import Hind from '../../assets/fonts/Hind/Hind-Regular.ttf'
import HindBold from '../../assets/fonts/Hind/Hind-SemiBold.ttf'
import PTBold from '../../assets/fonts/Futura/FuturaPTBold.otf'

const Card = styled(TouchableOpacity)`
  margin: ${(props) =>
    props.variant === 'horizontal'
      ? `${sizes.sm}px ${sizes.md}px ${sizes.sm}px 0`
      : `${sizes.sm}px 0`};
  width: ${(props) => (props.variant === 'horizontal' ? '245px' : '100%')};
  box-shadow: ${layout.boxShadow};
  border-radius: ${layout.borderRadius};
  overflow: hidden;
  position: relative;
`

const Contents = styled(View)`
  height: 100%;
  padding: ${sizes.sm}px;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
`
const Title = styled(Text)`
  font-family: 'HindBold';
  font-size: ${sizes.base}px;
  line-height: ${sizes.md}px;
  color: ${colors.white};
  margin-bottom: 0;
`

const Tag = styled(Text)`
  font-family: 'Hind';
  color: ${colors.white};
`

const Overlay = styled(LinearGradient)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
`

const Icon = styled(View)`
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  flex-direction: row;
  padding: ${sizes.xxxs}px;
  border-radius: ${sizes.xxxs}px;
  background-color: ${colors.white};
`

const CourseCard = ({ itemId, itemImage, name, variant, completed }) => {
  const navigation = useNavigation()
  const [isLoaded] = useFonts({
    Hind: Asset.fromModule(Hind).uri,
    HindBold: Asset.fromModule(HindBold).uri,
    PTBold: Asset.fromModule(PTBold).uri,
  })

  const itemCompleted = () => {
    return includes(itemId, completed)
  }

  return (
    <Card
      onPress={() => navigation.navigate('Course', { id: itemId })}
      variant={variant}>
      <ImageBackground
        source={{ uri: itemImage }}
        style={{ height: 200, width: '100%', resizeMode: 'contain' }}>
        {itemCompleted() && (
          <Icon>
            <AntDesign
              name='checkcircleo'
              size={sizes.sm}
              color={colors.green}
            />
            <Text style={{ marginLeft: 5, color: colors.green }}>
              Completed
            </Text>
          </Icon>
        )}

        <Contents>
          <Title>{isObject(name) ? name.S : name}</Title>
        </Contents>
        <Overlay colors={['rgba(0,0,0,0.1)', 'rgba(0,0,0,0.5)']} />
      </ImageBackground>
    </Card>
  )
}

export default CourseCard
