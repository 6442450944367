import { client, custom } from './client'
import { Auth } from 'aws-amplify'
import Constants from 'expo-constants'
const { USER_ID } = Constants.manifest.extra

const getCourse = async (id) => {
  try {
    const user = (await Auth.currentAuthenticatedUser()) || USER_ID
    const jwt = user.signInUserSession.idToken.jwtToken
    const response = await custom.get(`/courses/${id}`, {
      headers: { Authorization: jwt },
    })
    if (response.data) {
      const data = JSON.parse(response.data.body)
      return data.Item
    } else {
      return { err: 'error' }
    }
  } catch (error) {
    return { err: error }
  }
}

const getCourseChapters = async (id) => {
  try {
    const response = await client.get(`/courses/${id}/chapters`)
    return response.data
  } catch (error) {
    return { err: error }
  }
}

const getCourses = async () => {
  try {
    const response = await client.get('/courses')
    return response.data
  } catch (error) {
    return { err: error }
  }
}

export { getCourse, getCourseChapters, getCourses }
