import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { colors } from '../styles/variables'

// Pages
import User from '../pages/profile'

const CoursesStack = createStackNavigator()

const UserMenu = () => (
  <CoursesStack.Navigator
    initialRouteName='Your Profile'
    screenOptions={{
      headerShown: false,
      // headerStyle: {
      //   backgroundColor: colors.jet,
      // },
      // headerTitleStyle: {
      //   fontWeight: 'bold',
      // },
      // headerBackTitleVisible: false,
      // headerTintColor: colors.white,
    }}
  >
    <CoursesStack.Screen component={User} name='User' />
  </CoursesStack.Navigator>
)

export default UserMenu
