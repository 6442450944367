import React from 'react'
import { useFonts } from '@use-expo/font'
import { View, Text } from 'react-native'
import styled from 'styled-components'
import startCase from 'lodash/startCase'
import { Ionicons } from '@expo/vector-icons'
import { sizes, colors, textStyles } from '../styles/variables'
import { getClawColor } from '../utils/utils'
import Loading from '../components/loading'

const Card = styled(View)`
  padding: ${sizes.sm}px ${sizes.md}px ${sizes.md}px;
  margin: ${sizes.md}px 0;
  position: relative;
`

const Title = styled(Text)`
  font-family: 'PTBold';
  ${textStyles.title};
  color: ${colors.white};
  font-size: ${sizes.lg}px;
  margin-bottom: 0;
`

const StatsCard = styled(View)`
  background-color: ${colors.darkGrey};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: ${sizes.sm}px 0;
  padding: ${sizes.sm}px ${sizes.ms}px;
`

const StatsCardSection = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StatBlock = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const Stat = styled(Text)`
  font-family: 'PTBold';
  ${textStyles.title};
  color: ${colors.white};
  margin-bottom: ${sizes.xxxs}px;
  font-size: ${sizes.lg}px;
  line-height: ${sizes.lg}px;
`

const ClawBar = styled(View)`
  background-color: ${(props) =>
    props.clawColor ? props.clawColor : colors.grey};
  position: absolute;
  bottom: ${sizes.xxl}px;
  left: ${sizes.md}px;
  height: 5px;
  width: 100%;
`

const Flex = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Profile = ({
  first_name,
  points,
  pointsTotal,
  courses,
  lessons,
  colour,
  loaded,
}) => {
  const [isLoaded] = useFonts({
    Hind: require('../../assets/fonts/Hind/Hind-Regular.ttf'),
    HindBold: require('../../assets/fonts/Hind/Hind-SemiBold.ttf'),
    PTBold: require('../../assets/fonts/Futura/FuturaPTBold.otf'),
  })
  const greeting = () => {
    const date = new Date()
    const hour = date.getHours()
    if (hour > 0 && hour < 11) {
      return 'Morning '
    } else if (hour >= 11 && hour < 17) {
      return 'Afternoon '
    } else {
      return 'Evening '
    }
  }

  return (
    isLoaded && (
      <Card>
        <Title>
          {greeting()}
          {startCase(first_name)}
        </Title>
        <StatsCard>
          <View>
            {loaded ? (
              <StatBlock>
                <Stat>{colour ? startCase(colour) : 'Omega'}</Stat>
                <Text
                  style={{
                    color: colors.white,
                    lineHeight: sizes.sm,
                  }}
                >
                  Status
                </Text>
              </StatBlock>
            ) : (
              <Loading />
            )}
          </View>
          <StatsCardSection>
            <View>
              {loaded ? (
                <StatBlock>
                  <Stat>{points ? points : 0}</Stat>
                  <Flex>
                    <Text
                      style={{
                        color: colors.white,
                        marginRight: 5,
                        lineHeight: sizes.sm,
                      }}
                    >
                      Claws this month
                    </Text>
                  </Flex>
                </StatBlock>
              ) : (
                <Loading />
              )}
            </View>
            <View>
              {loaded ? (
                <StatBlock>
                  <Stat>{pointsTotal ? pointsTotal : 0}</Stat>
                  <Flex>
                    <Text
                      style={{
                        color: colors.white,
                        marginRight: 5,
                        lineHeight: sizes.sm,
                      }}
                    >
                      Lifetime Claws
                    </Text>
                  </Flex>
                </StatBlock>
              ) : (
                <Loading />
              )}
            </View>
          </StatsCardSection>
        </StatsCard>
        <ClawBar clawColor={getClawColor(colour)} />
      </Card>
    )
  )
}

export default Profile
