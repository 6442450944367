import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

// Pages
import About from '../pages/about'

const CoursesStack = createStackNavigator()

const UserMenu = () => (
  <CoursesStack.Navigator
    initialRouteName='About'
    screenOptions={{
      headerShown: false,
    }}>
    <CoursesStack.Screen component={About} name='About Wild Training' />
  </CoursesStack.Navigator>
)

export default UserMenu
