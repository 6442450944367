import { client, custom } from './client'
import { Auth } from 'aws-amplify'
import Constants from 'expo-constants'
const { USER_ID } = Constants.manifest.extra

const getUsers = async () => {
  try {
    const response = await client.get('/users')
    return response.data
  } catch (error) {
    return { err: error }
  }
}

const getUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const response = await client.get(
      `/users/${user.attributes['custom:uuid'] || USER_ID}`
    )
    return response.data
  } catch (error) {
    return { err: error }
  }
}

const getUserStats = async () => {
  try {
    const user = (await Auth.currentAuthenticatedUser()) || USER_ID
    const jwt = user.signInUserSession.idToken.jwtToken
    const response = await custom.get(
      `/users/${user.attributes['custom:uuid'] || USER_ID}`,
      { headers: { Authorization: jwt } }
    )
    return response.data
  } catch (error) {
    return { err: error }
  }
}

export { getUsers, getUser, getUserStats }
