import React, { useCallback, useState, useEffect } from 'react'
import { useFonts } from '@use-expo/font'
import {
  Image,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
  Text,
  View,
  RefreshControl,
} from 'react-native'
import { Asset } from 'expo-asset'
import { useNavigation, useRoute } from '@react-navigation/native'
import styled from 'styled-components'
import groupBy from 'lodash/groupBy'
import { Ionicons } from '@expo/vector-icons'
import { colors, sizes, textStyles } from '../styles/variables'
import { getCourse } from '../services/courses'
import { getUserStats } from '../services/users'
import ChapterCard from '../components/chapterCard'
import Loading from '../components/loading'

// Fonts
import Hind from '../../assets/fonts/Hind/Hind-Regular.ttf'
import HindBold from '../../assets/fonts/Hind/Hind-SemiBold.ttf'
import PTBold from '../../assets/fonts/Futura/FuturaPTBold.otf'

// Styles
const Container = styled(SafeAreaView)`
  flex: 1;
  height: 100vh;
  background-color: ${colors.jet};
`

const Wrapper = styled(ScrollView)`
  height: calc(100vh - 47px);
`

const Intro = styled(View)`
  padding: ${sizes.xxxl}px ${sizes.xl}px;
  background-color: ${colors.oil};
`

const Title = styled(Text)`
  font-family: 'PTBold';
  ${textStyles.title};
  margin-bottom: ${sizes.xs}px;
  font-size: ${sizes.lg}px;
  color: ${colors.white};
`

const Excerpt = styled(Text)`
  ${textStyles.body};
  color: ${colors.white};
`

const Chapters = styled(View)`
  padding: ${sizes.xxxl}px ${sizes.xl}px 80px;
  background-color: ${colors.grey};
  position: relative;
  min-height: 300px;
`

const Angle = styled(View)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border-top-width: 50px;
  border-top-color: ${colors.oil};
  border-style: solid;
  border-left-width: 600px;
  border-left-color: ${colors.grey};
`

const Section = styled(View)`
  margin-bottom: ${sizes.md}px;
`

const SectionHeader = styled(View)`
  margin-top: ${sizes.md}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const SubTitle = styled(Text)`
  font-family: 'PTBold';
  ${textStyles.title};
  font-size: ${sizes.base}px;
  max-width: 70%;
`

const Header = styled(TouchableOpacity)`
  position: absolute;
  width: 100%;
  padding: 15px 20px;
  background-color: ${colors.overlay}
  top: 0;
  left: 0;
  z-index: 4;
`

const Course = () => {
  const navigation = useNavigation()
  const route = useRoute()
  const { params } = route
  const [course, setCourse] = useState({})
  const [chapters, setChapters] = useState([])
  const [userStats, setUserStats] = useState([])
  const [contentLoaded, setContentLoaded] = useState(false)
  const [refreshing, setRefreshing] = useState(false)

  const [isLoaded] = useFonts({
    Hind: Asset.fromModule(Hind).uri,
    HindBold: Asset.fromModule(HindBold).uri,
    PTBold: Asset.fromModule(PTBold).uri,
  })

  useEffect(() => {
    const { id } = params
    fetchCourse(id)
    fetchUser()
  }, [refreshing])

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    setContentLoaded(false)
    setTimeout(() => {
      setRefreshing(false)
    }, 600)
  }, [])

  const fetchCourse = async (id) => {
    const courseResult = await getCourse(id)
    if (courseResult) {
      setCourse(courseResult)
      const arr = JSON.parse(courseResult.chapters.S)
      const grouped = groupBy(arr, 'chapter_id')
      setChapters(grouped)
      navigation.setOptions({ title: courseResult.name.S })
    }
    setContentLoaded(true)
  }

  const fetchUser = async () => {
    const stats = await getUserStats()
    if (!stats.err) {
      setUserStats(stats)
    }
  }

  return (
    isLoaded &&
    contentLoaded && (
      <Container>
        <Wrapper
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              tintColor={colors.white}
            />
          }>
          <Header onPress={() => navigation.navigate('Welcome')}>
            <Ionicons name='ios-arrow-back' size={24} color={colors.white} />
          </Header>
          <Image
            source={{
              uri: course.course_card_image_url.S,
            }}
            style={{
              height: 300,
              width: '100%',
              resizeMode: 'cover',
              backgroundColor: colors.darkGrey,
            }}
          />
          <Intro>
            <Title>{course.name && course.name.S}</Title>
            <Excerpt>{course.description && course.description.S}</Excerpt>
          </Intro>

          <Chapters>
            <Angle />
            {contentLoaded ? (
              <>
                {chapters &&
                  Object.entries(chapters).map(([key, value]) => {
                    return (
                      <Section key={key}>
                        <SectionHeader>
                          <SubTitle>{value[0].name}</SubTitle>
                        </SectionHeader>
                        {value.map((c) => (
                          <ChapterCard
                            key={c.id}
                            {...c}
                            completed={userStats.chapters}
                          />
                        ))}
                      </Section>
                    )
                  })}
              </>
            ) : (
              <Loading />
            )}
          </Chapters>
        </Wrapper>
      </Container>
    )
  )
}

export default Course
